<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.lottoOffer') }}</h5>
                <DataTable
                    :value="items"
                    @page="onPage($event)"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-lottoresults"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown :showClear="true" style="width: 100%" v-model="lotteryModel" :options="lotteries" :placeholder="$t('dropdown.placeholder.lottery')" optionLabel="name" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-4 p-text-right"><Button :label="$t('buttons.getDraws')" class="p-button-raised p-button-success" @click="getOffer()" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="{ data }">
                            <Button :label="isBlockedText(data.isBlocked)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + isBlockedColor(data.isBlocked) + ' p-mr-2'" />
                        </template>
                    </Column>
                    <Column field="offerNo" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                    <Column field="drawDate" :header="$t('lotto.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.drawDate) }} </template></Column
                    >
                    <Column field="lotteryName" :header="$t('lotto.lotteryName')" :sortable="true"></Column>
                    <Column field="drawId" :header="$t('lotto.drawId')">
                        <template #body="{ data }"><Button class="p-button p-button-text p-button-info" :label="data.drawId" @click="redirectToTickets(data.drawId)"></Button></template>
                    </Column>
                    <Column field="ticketsNo" :header="$t('lotto.ticketsNo')" :sortable="true"> </Column>

                    <Column :exportable="false" class="p-text-right">
                        <template #body="{ data }">
                            <Button v-if="data.isBlocked == false" :label="$t('buttons.block')" class="p-button-danger p-button-text p-mr-2" @click="blockDraw(data.drawId, true)" />
                            <Button v-else :label="$t('buttons.activate')" class="p-button-success p-button-text p-mr-2" @click="blockDraw(data.drawId, false)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { LottoApi } from '../../service/LottoApi';
import { BoLottoApi } from '../../service/BoLottoApi';
import { uiSettings } from '../../settings/uiSettings';
export default {
    name: 'publishLottoOffer',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            perPage: uiSettings.tableRows,
            startDateModel: null,
            endDateModel: null,
            crtPage: 1,
            lotteries: [],
            lotteryModel: { name: 'All', id: 0, code: 0 },
            LottoApi: new LottoApi(),
            BoLottoApi: new BoLottoApi(),
            items: [],
            loading: false,
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.getLotteriesFilter();
    },
    watch: {
        lotteryModel() {
            if (!this.notNullOrEmptyObject(this.lotteryModel)) {
                this.lotteryModel = { name: 'All', id: '', code: 0 };
            }
            this.getOffer();
        },
    },
    methods: {
        redirectToTickets(drawId) {
            this.goto('lottoTickets', { drawIdParam: drawId });
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
        getLotteriesFilter() {
            this.showErrorMessage = false;
            this.showOkMessage = false;
            this.LottoApi.getLotteriesForFilter()
                .then((response) => {
                    this.lotteries = response.data;
                    this.getOffer();
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        getOffer() {
            this.loading = true;
            this.LottoApi.getCurrentOffer(this.formatEnDateAndTime(this.startDateModel.toString()), this.formatEnDateAndTime(this.endDateModel.toString()), this.lotteryModel.code)
                .then((response) => {
                    this.loading = false;

                    this.items = response.data;
                })
                .catch(() => {
                    this.loading = false;

                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        blockDraw(drawId, status) {
            this.BoLottoApi.blockDraw(drawId, status)
                .then(() => {
                    this.getOffer();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        isBlockedColor(isBlocked) {
            return isBlocked ? 'danger' : 'success';
        },
        isBlockedText(isBlocked) {
            return isBlocked ? this.$t('lotto.blocked') : this.$t('lotto.active');
        },
    },
};
</script>
                